<template>
  <div v-if="research.formData" class="container p-4">
    <div v-if="!acceptFlg || !research.sampleExpireFlg" class="mb-5 col-12 px-0 px-sm-3 mx-auto text-left" ref="titleheader">採取予定日、試料回収希望日時を確認してください。</div>
    <form ref="form1" @submit.prevent>
      <div class="form-group row" v-if="acceptFlg && !research.sampleExpireFlg">
        <label class="col-sm-3 col-form-label p-sm-1">採取予定日</label>
        <div class="col-sm-6">
        {{ day1 }}
        </div>
        <br><br>
      </div>
      <div class="form-group row" v-if="acceptFlg && !research.sampleExpireFlg">
        <label class="col-sm-3 col-form-label p-sm-1">試料回収希望日時</label>
        <div class="col-sm-6">
          <div v-for="(d2, idx) in day2" v-bind:key="d2" class="mb-5">
            <div class="mb-2">{{ getFormatDate(chars8ToDate(idx)) }}</div>
            <div v-for="d in d2" v-bind:key="d">{{ getFormatTime(d) }}</div>
          </div>
        </div>
      </div>
      <div v-if="!acceptFlg || !research.sampleExpireFlg" class="form-group row justify-content-center mt-5">
        <div class="col-sm-6">
          <router-link :to="{name: 'RegisterResearch'}" class="btn_next btn btn-secondary" role="button" v-if="acceptFlg">修正する</router-link>
          <router-link :to="{name: 'RegisterResearch'}" class="btn_next btn btn-secondary" role="button" v-else>&nbsp;&nbsp;&nbsp;&nbsp;戻る&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
        </div>
      </div>
      <div v-if="acceptFlg">
        <div class="my-5 col-12 px-0 px-sm-3 mx-auto text-left">ご自宅に2種類の試料採取キットが送付されます。アンケートはオンラインで回答して頂けますが、紙面回答用のアンケート用紙も試料採取キットとともに送付されます。<br>以下のアンケートAはオンラインのみの回答です。1分程度かかります。<br><br>採取予定日、試料回収希望日時の確認ができましたら、アンケートAへの回答をお願いします。</div>
        <div class="form-group row justify-content-center mb-4">
          <button type="button" class="btn_next btn btn-primary" @click="onClickButton">アンケートに答える</button>
        </div>
        <div v-if="research.sampleExpireFlg" class="form-group row justify-content-center">
          <router-link :to="{name: 'RegisterResearch'}" class="btn_next btn btn-secondary" role="button">&nbsp;&nbsp;&nbsp;&nbsp;戻る&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
        </div>
      </div>
      <div class="mt-4" v-else>
        <button type="button" class="btn_next btn btn-primary" @click="onDecline">研究への参加を撤回</button>
      </div>
    </form>
  </div>
</template>
<script>
import { Mixin } from '@/mixin.js'
import { getMemberCode, getRequestSecretKey } from '@/utils/common.js';
import { getActivateFlg } from '@/api/member.js';
import { CONST } from '@/constants.js';
import { apiError } from '@/api/common.js'
import { decline } from '@/api/survey.js';
import { makeRequestByAes } from '@/utils/make-request-data.js'

export default {
  mixins: [Mixin],
  data() {
    return {
      day1: null,
      day2: null,
      sampleDay: null,
      schedules: [],
    }
  },
  async created() {
    this.memberCode = getMemberCode()
    this.requestSecretKey = getRequestSecretKey()
    if (!this.memberCode || !this.requestSecretKey) {
      alert('ログイン情報が見つかりません。ログイン画面に戻ります。')
      this.logout()
      return
    }
    const activate_flg = await getActivateFlg(this.memberCode)

    switch(activate_flg.value) {
      case CONST.MEMBER.ACTIVATE_FLG.RESEARCH_SUBJECT:
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_AGREE:
        this.$router.push({name: 'Explain'})
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_COMPLETED:
        this.$router.push({name: 'ProductionComplete'})
        break
      default:
        this.$router.push({name: 'LoginPage'})
        break
    }
  },
  beforeMount() {
    this.accept = localStorage.getItem('accept')
    this.acceptFlg = this.accept !== null && this.accept !== '0'
  },
  mounted() {
    if (!this.acceptFlg && this.$refs.titleheader) {
      this.$refs.titleheader.innerHTML = '研究への参加を撤回します。よろしいですか？'
    }
    if (this.research.sampleExpireFlg) return
    try {
      this.sampleDay = localStorage.getItem('sampleDay')
      if (!(
        parseInt(this.research.sampleStartDate) <= parseInt(this.sampleDay) &&
        parseInt(this.sampleDay) <= parseInt(this.research.sampleEndDate)
      )) {
        throw new Error()
      }
      this.schedules = JSON.parse(localStorage.getItem('collectionDays'))
    } catch {
      this.sampleDay = null
      this.schedules = null
    }

    if (!(
      this.accept === '0' ||
      (this.accept === '1' && this.sampleDay !== null && this.schedules !== null)
    )) {
       alert('採取予定日または試料回収希望日時が未選択です。前の画面に戻ります。')
       this.$router.push({name: 'RegisterResearch'})
       return
    } else if (this.accept !== '0') {
      this.research.days = this.getCandidateDates(this.research.startDate, this.research.endDate)
      this.research.sampleDays = this.getCandidateDates(this.research.sampleStartDate, this.research.sampleEndDate)

      this.day1 = this.getFormatDate(this.chars8ToDate(this.sampleDay))
      let nowDate
      this.day2 = this.schedules.reduce((arr, date) => {
        const getDate = date.slice(0, 8)
        if (nowDate != getDate) {
          nowDate = getDate
          arr[nowDate] = []
        }
        arr[nowDate].push(date)
        return arr
      }, {})
    }
  },
  methods: {
    async onClickButton() {
      this.$router.push('/survey/answer/7')
    },
    async onDecline() {
      if (confirm('参加を撤回すると、ログイン情報が無効になり、再度研究に参加することができません。研究の参加を撤回しますか？')) {
        const data = makeRequestByAes(this.memberCode, this.requestSecretKey, {
          member_code: this.memberCode,
        })
        const res = await decline(data).catch(apiError)
        if (res && res.value) {
          this.$router.push({name: 'DeclineComplete'})
        }
      }
    },
  },
}
</script>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
